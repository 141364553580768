h1, .h1 {
  color: black;
  font-weight: 600;
  font-size: 1.94444rem;
  line-height: 1.3;
  margin: 0 0 20px;
}

h3.h2 {
  color: black;
  font-size: 1.66667rem;
  margin: 0 0 12px;
}

.fs-25 {
  font-size: 1.38889rem;
}

.text-black {
  color: black;
}

.text-gallery {
  font-size: 1.38889rem;
  color: #f0f0f0;
  font-weight: 600;
  letter-spacing: -1px;
}

[class*="icon"] {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-eye {
  width: 35px;
  height: 25px;
  background-size: 35px auto;
  background-image: url("../images/icons/eye.png");
  cursor: pointer;
}

.icon-tick {
  background-image: url("../images/icons/tick.png");
  background-size: contain;
  height: 30px;
  width: 33px;
}

.icon-paint {
  background-image: url("../images/icons/paint.png");
  background-size: contain;
  height: 30px;
  width: 33px;
}

.icon-photo {
  background-image: url("../images/icons/photo.png");
  background-size: contain;
  height: 30px;
  width: 33px;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 18px;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  color: #6B6B6B;
  font-weight: 500;
}

body {
  background-color: #ECECEC;
}

body.dark-theme {
  background-color: #1D1D1F;
}

body.white-theme {
  background-color: white;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, address, section, video, audio {
  border: 0;
  outline: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

a img {
  border: none;
}

a {
  text-decoration: none;
  transition: color .3s ease;
  color: black;
}

a:hover, a:active {
  color: #383838;
}

label {
  display: inline-block;
}

.align-items-center {
  align-items: center;
}

.container {
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 3000px;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.gap-10 {
  gap: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.height-0 {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden;
  visibility: hidden;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}

.w-100 {
  width: 100%;
}

@media (min-width: 575px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg {
    flex: 1 0 0%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .d-lg-block {
    display: block !important;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.mx_0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.mb_5 {
  margin-bottom: 5px !important;
}

.mb_10 {
  margin-bottom: 10px !important;
}

.mb_15 {
  margin-bottom: 15px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.ms_0 {
  margin-left: 0 !important;
}

.ms_5 {
  margin-left: 5px !important;
}

.ms_10 {
  margin-left: 10px !important;
}

.ms_20 {
  margin-left: 20px !important;
}

.me_5 {
  margin-right: 5px !important;
}

.mt_20 {
  margin-top: 20px !important;
}

.mt_27 {
  margin-top: 27px !important;
}

.mt_35 {
  margin-top: 35px !important;
}

.my_10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my_15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my_25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.p_0 {
  padding: 0 !important;
}

.pe_0 {
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .mb-sm_0 {
    margin-bottom: 0 !important;
  }
  .mb-sm_20 {
    margin-bottom: 20px !important;
  }
  .ms-sm_9 {
    margin-left: 9px !important;
  }
  .pe-sm_0 {
    padding-right: 0px !important;
  }
  .pe-sm_40 {
    padding-right: 40px !important;
  }
}

@media (min-width: 768px) {
  .pe-md_0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .ms-lg_20 {
    margin-left: 20px !important;
  }
  .pe-lg_0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .pe-xl_40 {
    padding-right: 40px !important;
  }
  .mb-xl_0 {
    margin-bottom: 0 !important;
  }
}

.form-area {
  border-radius: 15px;
  background-color: #ECECEC;
  padding: 46px 20px 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: .53rem 15px .55rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6B6B6B;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid transparent;
  appearance: none;
  border-radius: 5px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: "Inter", sans-serif;
}

.form-control::placeholder {
  opacity: 1;
  color: lightgray;
}

.form-control.form-control-small {
  width: 50px;
  text-align: center;
  padding: .11rem 5px .14rem;
  border: 1px solid #CBCBCB;
}

textarea.form-control {
  line-height: 1.2;
}

.form-text {
  text-align: right;
  font-size: 0.88889rem;
  margin-top: 3px;
}

.form-check {
  min-height: 1.5rem;
  padding-left: 17px;
  display: flex;
  align-items: center;
}

input.form-check-input {
  border-radius: 5px;
  float: left;
  margin: 0 7px 0 -17px;
  width: 26px;
  height: 25px;
  border: 1px solid #CBCBCB;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

input.form-check-input:checked {
  background-color: #73D412;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-position: center;
  border-color: #73D412;
}

.form-check-label {
  font-size: 0.88889rem;
}

.form-label {
  color: #A4A4A4;
  font-size: 0.83333rem;
  width: 100%;
  padding: 0 0 5px 15px;
}

.form-item-in {
  opacity: 1;
  transition: opacity ease-in .5s;
  margin-top: 35px;
}

@media (min-width: 576px) {
  .form-area {
    padding: 46px 33px;
  }
  .form-control {
    padding: .33rem 15px .36rem;
  }
  .form-text {
    font-size: 0.77778rem;
    color: #969696;
  }
  .form-text a {
    text-decoration: underline;
    color: #969696;
  }
  .form-text a:hover, .form-text a:active {
    text-decoration: none;
  }
  .form-check-label {
    font-size: 0.77778rem;
  }
  input.form-check-input {
    width: 22px;
    height: 21px;
    margin: 1px 5px 0 -17px;
  }
  .form-check-2 .form-check-label {
    font-size: 1.38889rem;
  }
  .form-check-2 input.form-check-input, .form-check-3 input.form-check-input {
    margin-right: 10px;
  }
}

.btn, a.btn, .header-main a.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.38889rem;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: button;
  padding: 16px 73px 19px;
  border-radius: 37px;
  border: 1px solid;
}

.btn-primary, a.btn-primary, .header-main a.btn-primary {
  background-color: black;
  border-color: black;
  color: white;
}

.btn-primary:hover, .btn-primary:active, a.btn-primary:hover, a.btn-primary:active, .header-main a.btn-primary:hover, .header-main a.btn-primary:active {
  background-color: #383838;
  border-color: #383838;
}

.btn-primary:disabled, a.btn-primary:disabled, .header-main a.btn-primary:disabled {
  opacity: .1;
  cursor: default;
}

.btn-primary:disabled:hover, .btn-primary:disabled:active, a.btn-primary:disabled:hover, a.btn-primary:disabled:active, .header-main a.btn-primary:disabled:hover, .header-main a.btn-primary:disabled:active {
  background-color: black;
  border-color: black;
}

.btn-secondary, a.btn-secondary, .header-main a.btn-secondary {
  background-color: #747474;
  border-color: #747474;
  color: white;
}

.btn-secondary:hover, .btn-secondary:active, a.btn-secondary:hover, a.btn-secondary:active, .header-main a.btn-secondary:hover, .header-main a.btn-secondary:active {
  background-color: #8e8d8d;
  border-color: #8e8d8d;
}

.btn-grey, a.btn-grey, .header-main a.btn-grey {
  color: #1D1D1F !important;
  background-color: #D2D2D2;
  border-color: #D2D2D2;
}

.btn-grey:hover, .btn-grey:active, a.btn-grey:hover, a.btn-grey:active, .header-main a.btn-grey:hover, .header-main a.btn-grey:active {
  background-color: #f0f0f0;
}

.btn-medium, a.btn-medium, .header-main a.btn-medium {
  font-size: 1rem;
  padding: 9px 42px;
}

.btn-medium-2, a.btn-medium-2, .header-main a.btn-medium-2 {
  padding-left: 45px;
  padding-right: 45px;
}

@media (min-width: 576px) {
  .btn {
    font-size: 1.11111rem;
    padding: 13px 73px 14px;
  }
  .btn-medium {
    font-size: 0.83333rem;
  }
  .btn-medium-2 {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.custom-tooltip {
  background-color: black;
  color: white;
  display: inline-block;
  border-radius: 10px;
  padding: 5px 10px 10px;
  font-size: 0.66667rem;
  position: relative;
  max-width: 200px;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: black transparent transparent transparent;
  bottom: -4px;
  left: 50%;
  margin-left: -2px;
}

.header-main .dropdown {
  position: relative;
}

.header-main .dropdown-menu {
  position: absolute;
  display: none;
  z-index: 1000;
  list-style: none;
  background-color: #fff;
  font-size: 0.83333rem;
  line-height: 24px;
  margin: 0;
  padding: 0;
  min-width: 160px;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
}

.header-main .dropdown-menu.show {
  display: block;
  margin: 23px 0 0 !important;
}

.header-main .dropdown-menu a.dropdown-item {
  margin: 0;
  color: #515151;
  display: block;
  padding: 5px 15px;
  text-align: left;
}

.header-main .dropdown-menu a.dropdown-item:hover, .header-main .dropdown-menu a.dropdown-item:active {
  background-color: #D9D9D9;
}

@media (max-width: 574px) {
  .header-right-menu .dropdown-menu {
    width: calc(100% + 74px);
  }
  .header-right-menu .dropdown-menu.show {
    transform: translateX(-37px) !important;
    inset: auto !important;
    margin: 21px 0 0 !important;
  }
}

@media (min-width: 575px) {
  .header-main .dropdown-menu.show {
    margin: 13px 0 0 !important;
  }
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-nav li {
  display: inline-block;
}

.navbar {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  background-color: transparent;
  cursor: pointer;
  height: 37px;
}

.navbar-toggler span {
  display: inline-block;
  width: 48px;
  height: 37px;
  background-image: url(../images/icons/close-icon.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.navbar-toggler[aria-expanded="false"] span {
  background-image: url(../images/icons/hamburger-icon.svg);
}

.collapse:not(.show) {
  display: none;
}

@media (max-width: 991px) {
  .navbar-collapse {
    width: 100%;
    background-color: #373737;
    display: flex;
    opacity: 0;
    top: 60px;
    position: absolute;
    left: 0;
    transform: translateY(-100px) translateX(0px);
    transition: all 300ms ease 0s;
    justify-content: flex-end;
    padding: 11px 18px 12px;
    z-index: -1;
  }
  .navbar-collapse.show {
    transform: translateY(0px) translateX(0px);
    opacity: 1;
    z-index: 1000;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .header-main-submenu {
    position: absolute;
    z-index: 1003;
    right: 40px;
    top: 11px;
  }
}

.header-main {
  background-color: #000000;
  position: relative;
  z-index: 1001;
  padding: 9px 0;
  height: 60px;
  position: fixed;
  width: 100%;
}

.header-main a {
  color: white;
  font-size: 0.83333rem;
  margin-left: 45px;
  font-weight: 500;
}

.header-main a.bold {
  font-weight: bold;
}

.header-main a.login_text {
  color: #D9D9D9;
}

.header-main .dropdown-menu {
  right: -18px;
  top: 60px;
  padding: 5px 0;
}

.header-main-submenu a {
  color: white;
  font-size: 0.83333rem;
  margin-left: 45px;
  font-weight: 600;
}

.header-main-submenu a.bold {
  font-weight: bold;
}

.header-main-submenu a.login_text {
  color: #D9D9D9;
}

.header-main > .container, .header-main-submenu > .container, .header-main > .container-fluid {
  padding-right: 35px;
  padding-left: 35px;
}

.header-main-2 > .container {
  padding-right: 55px;
  padding-left: 55px;
}

.white-theme .header-main {
  background-color: white;
}

.white-theme .header-main a {
  color: #1D1D1F;
}

.white-theme .header-main-logo a {
  background-image: url("../images/common/vicaso_logo_black.png");
}

.header-main-logo a {
  display: inline-block;
  margin-left: 15px;
}

.header-main-logo a img {
  width: 110px;
  height: 33px;
}

.header-user {
  width: 33px;
  height: 33px;
  display: inline-block;
  border-radius: 50%;
  background-color: #73D412;
  margin-right: 10px;
}

.header-main + .header-main-submenu + main, .header-main + main {
  padding-top: 60px;
}

@media (max-width: 575px) {
  .header-main, .header-main-submenu {
    text-align: center;
  }
  .header-main a, .header-main-submenu a {
    font-size: 1rem;
    margin: 0 10px;
  }
  .header-main-logo {
    margin: 10px 0 20px;
  }
  .header-right-menu {
    margin-bottom: 10px;
  }
}

.footer-main {
  background-color: white;
  padding: 18px 0 19px;
  text-align: center;
}

.footer-main ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-main ul li {
  margin: 10px 20px;
}

.footer-main ul li a {
  font-weight: 600;
  color: #949191;
  font-size: 1.22222rem;
}

.footer-main ul li a:hover, .footer-main ul li a:active {
  color: #b8b7b7;
}

.footer-main-2 {
  padding: 28px 0 29px;
}

.dark-theme .footer-main {
  background-color: transparent;
}

.white-theme .footer-main {
  background-color: #1D1D1F;
}

@media (min-width: 576px) {
  .footer-main ul {
    display: flex;
    justify-content: center;
  }
  .footer-main ul li {
    margin: 0 20px;
  }
  .footer-main ul li a {
    font-size: 0.83333rem;
  }
}

@media (min-width: 992px) {
  .footer-main ul li {
    margin: 0 51px;
  }
}

.login-page {
  background-color: #ECECEC;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-area {
  width: 100%;
}

.login-area .btn-primary {
  width: 100%;
}

.login-area-logo {
  display: inline-block;
  width: 135px;
  height: 40px;
  margin-bottom: 20px;
  background-size: contain;
  background-image: url("../images/common/vicaso_logo_black.png");
}

@media (min-width: 576px) {
  .login-page {
    background-image: url("../images/content/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .login-area-logo {
    background-image: url("../images/common/vicaso_logo_white.png");
  }
  .login-area {
    max-width: 340px;
  }
  .login-area .btn-primary {
    width: auto;
  }
}

.content-area {
  max-width: 660px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.content-items-area {
  background-color: #F8F8F8;
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 24px;
  border: 2px solid #F8F8F8;
  max-width: 580px;
}

.content-items-area.selected {
  border-color: #000;
}

.photos-area {
  min-height: calc(100vh - 117px);
  background-size: cover;
  background-position: center;
}

.map-area {
  min-height: calc(100vh - 117px);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.map-area #mapView {
  min-height: 100% !important;
}

.video-area, .threeD-tours {
  padding: 0;
}

.video-area iframe, .threeD-tours iframe {
  height: 375px;
  width: 100%;
  margin: 0;
  padding: 0;
}

@media (max-width: 991px) {
  .content-area {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }
  .content-items-area {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .content-area {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.gallery-item {
  border-radius: 15px;
  overflow: hidden;
  height: 338px;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 0;
}

.gallery-item:hover .gallery-text, .gallery-item:hover .gallery-text-2, .gallery-item:active .gallery-text, .gallery-item:active .gallery-text-2 {
  opacity: 1;
}

.gallery-text, .gallery-text-2 {
  color: white;
  font-weight: 600;
  font-size: 1.38889rem;
  padding: 16px 25px;
  position: absolute;
  width: 101%;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity .5s ease;
  border-radius: 0 0 15px 15px;
}

.gallery-text {
  background-color: rgba(28, 28, 28, 0.6);
}

.gallery-text-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.gallery-item-sold {
  font-size: 2.77778rem;
  font-weight: 600;
  color: white;
}

.gallery-item-sold .gallery-item-sold-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

[class*="gallery-edit"] {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

[class*="gallery-edit"]:hover, [class*="gallery-edit"]:active {
  background-image: none;
}

.gallery-edit-1 {
  background-color: #82B84D;
  background-image: url("../images/icons/tick.png");
  background-size: 104px auto;
}

.gallery-edit-2 {
  background-color: #14936D;
  background-image: url("../images/icons/paint.png");
  background-size: 93px auto;
}

.gallery-edit-3 {
  background-color: #356897;
  background-image: url("../images/icons/photo.png");
  background-size: 111px auto;
}

.gallery-edit-4 {
  background-color: #515151;
  background-image: url("../images/icons/send.png");
  background-size: 95px auto;
}

@media (max-width: 1199px) {
  [class*="gallery-edit"] {
    background-image: none;
  }
  .gallery-item .gallery-text, .gallery-item .gallery-text-2 {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .gallery-item {
    width: calc(50% - 7px) !important;
  }
}

@media (min-width: 1200px) {
  .gallery-item {
    width: calc(33.33333333% - 7px) !important;
  }
}

.profile-area {
  background-color: #ECECEC;
  border-radius: 15px;
  padding: 23px;
  margin-bottom: 30px;
}

.profile-photo-upload {
  width: 223px;
  height: 223px;
  background-color: #D9D9D9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 17px;
  margin: 0 auto 18px;
}

.profile-photo-upload label {
  color: #2F2F2F;
  font-size: 0.83333rem;
  display: block;
  text-align: center;
  background-color: white;
  border: 1px solid #BEBEBE;
  border-radius: 37px;
  padding: 5px 18px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
