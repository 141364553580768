.footer-main{
  background-color: white;
  padding: 18px 0 19px;
  text-align: center;

  ul{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      margin: 10px 20px;

      a{
        font-weight: 600;
        color: $grey-3;
        font-size: remCalc(22);

        &:hover, &:active{
          color: $grey-3-hover;
        }
      }
    }
  }
}

.footer-main-2{
  padding: 28px 0 29px;
}

.dark-theme .footer-main{
  background-color: transparent;
}

.white-theme .footer-main{
  background-color: $black;
}

@media(min-width: 576px){
  .footer-main{
    ul{
      display: flex;
      justify-content: center;

      li{
        margin: 0 20px;

        a{
          font-size: remCalc(15);
        }
      }
    }
  }
}

@media(min-width: 992px){
  .footer-main ul li{
    margin: 0 51px;
  }
}