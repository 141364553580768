*, ::after, ::before {
  box-sizing: border-box;
}

html{
  overflow-x: hidden;
  font-size: $base-font-size + px;
  scroll-behavior: smooth;
  font-family: $base-font-family;
  color: $grey;
  font-weight: 500;
}

body{
  background-color: $grey-bg;

  &.dark-theme{
    background-color: $black;
  }

  &.white-theme{
    background-color: white;
  }
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, address, section, video, audio {
  border: 0;
  outline: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

a img {
  border: none;
}

a{
  text-decoration: none;  
  transition: color .3s ease;
  color: black;

  &:hover, &:active{
    color: $black-hover;
  }
}

label{
  display: inline-block;
}

.align-items-center{
  align-items: center;
}

.container{
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid{
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 3000px;
}

.d-flex{
  display: flex !important;
}

.d-none{
  display: none !important;
}

.d-inline-flex{
  display: inline-flex !important;
}

.gap-10{
  gap: 10px;
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.justify-content{
  &-between{
    justify-content: space-between;
  }

  &-end{
    justify-content: flex-end;
  }
}

.height-0{
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden;
  visibility: hidden;
}

.nowrap{
  white-space: nowrap;
}

.text{
  &-center{
    text-align: center !important;
  }

  &-end{
    text-align: right !important;
  }
}

.w-100{
  width: 100%;
}

@media(min-width: 575px){
  .col-sm {
    flex: 1 0 0%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-6{
    flex: 0 0 auto;
    width: 50%;
  }

  .d-sm-flex{
    display: flex !important;
  }

  .justify-content-sm-end{
    justify-content: flex-end !important;
  }

  .text-sm-start{
    text-align: left !important;
  }

  .text-sm-end{
    text-align: right !important;
  }

   .w-sm-50{
    width: 50% !important;
   }
}

@media(min-width: 768px){
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6{
    flex: 0 0 auto;
    width: 50%;
  }
}

@media(min-width: 992px){
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg {
    flex: 1 0 0%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-6{
    flex: 0 0 auto;
    width: 50%;
  }

  .d-lg-block{
    display: block !important;
  }

  .text-lg-center{
    text-align: center;
  }
}

@media(min-width: 1200px){
  .d-xl-flex{
    display: flex !important;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}