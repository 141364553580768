.mx_0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mb_{
  &0{
    margin-bottom: 0 !important;
  }

  &5{
    margin-bottom: 5px !important;
  }

  &10{
    margin-bottom: 10px !important;
  }

  &15{
    margin-bottom: 15px !important;
  }

  &20{
    margin-bottom: 20px !important;
  }

  &30{
    margin-bottom: 30px !important;
  }
}

.ms_{
  &0{
    margin-left: 0 !important;
  }

  &5{
    margin-left: 5px !important;
  }  

  &10{
    margin-left: 10px !important;
  }

  &20{
    margin-left: 20px !important;
  }
}

.me_5{
  margin-right: 5px !important;
}

.mt{
  &_20{
    margin-top: 20px !important;
  }
  &_27{
    margin-top: 27px !important;
  }
  &_35{
    margin-top: 35px !important;
  }
}

.my{
  &_10{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  &_15{
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  &_25{
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}

.p_0{
  padding: 0 !important;
}

.pe_0{
  padding-right: 0 !important;
}

@media(min-width: 576px){
  .mb-sm{
    &_0{
      margin-bottom: 0 !important;
    }

    &_20{
      margin-bottom: 20px !important;
    }
  }

  .ms-sm_9{
    margin-left: 9px !important;
  } 

  .pe{
    &-sm_0{
      padding-right: 0px !important;
    }
    &-sm_40{
      padding-right: 40px !important;
    }
  } 
}

@media(min-width: 768px){
  .pe-md_0{
    padding-right: 0 !important;
  }
}

@media(min-width: 992px){
  .ms-lg_20{
    margin-left: 20px !important;
  }

  .pe-lg_0{
    padding-right: 0 !important;
  }
}

@media(min-width: 1200px){
  .pe-xl_40{
    padding-right: 40px !important;
  }

  .mb-xl_0{
    margin-bottom: 0 !important;
  }
}