.navbar-nav{
  list-style: none;
  padding: 0;
  margin: 0;

  li{
    display: inline-block;
  }
}

.navbar{
  display: flex;
  align-items: center;
}

.navbar-toggler{
  background-color: transparent;
  cursor: pointer;
  height: 37px;

  span{
    display: inline-block;
    width: 48px;
    height: 37px;
    background-image: url(../images/icons/close-icon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
  }

  &[aria-expanded="false"]{
    span{
    background-image: url(../images/icons/hamburger-icon.svg);
    }
  }
}

.collapse:not(.show) {
  display: none;
}

@media (max-width: 991px){
  .navbar-collapse{
    width: 100%;
    background-color: #373737;
    display: flex;
    opacity: 0;
    top: 60px;
    position: absolute;
    left: 0;
    transform: translateY(-100px) translateX(0px);
    transition: all 300ms ease 0s;
    justify-content: flex-end;
    padding: 11px 18px 12px;
    z-index: -1;

    &.show{
      transform: translateY(0px) translateX(0px);
      opacity: 1;
      z-index: 1000;
    }
  }
}

@media (min-width: 992px){
  .navbar-expand-lg{
    flex-wrap: nowrap;
    justify-content: flex-start;

    .navbar-toggler {
      display: none;
    }
  }

  .header-main-submenu{
    position: absolute;
    z-index: 1003;
    right: 40px;
    top: 11px;
  }
}