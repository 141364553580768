.header-main{
  background-color: #000000;
  position: relative;
  z-index: 1001;
  padding: 9px 0;
  height: 60px;
  position: fixed;
  width: 100%;

  a{
    color: white;
    font-size: remCalc(15);
    margin-left: 45px;
    font-weight: 500;

    &.bold {
      font-weight: bold;
    }

    &.login_text {
      color:#D9D9D9;
    }
  }

  .dropdown-menu {
    right: -18px;
    top:60px;
    padding: 5px 0;
  }
}

.header-main-submenu{
  
  a{
    color: white;
    font-size: remCalc(15);
    margin-left: 45px;
    font-weight: 600;

    &.bold {
      font-weight: bold;
    }

    &.login_text {
      color:#D9D9D9;
    }
  }
}

.header-main > .container, .header-main-submenu > .container, .header-main > .container-fluid{
  padding-right: 35px;
  padding-left: 35px;
}

.header-main-2 > .container{
  padding-right: 55px;
  padding-left: 55px;
}

.white-theme{
  .header-main{
    background-color: white;

    a{
      color: $black;
    }
  }

  .header-main-logo{
    a{
      background-image: url('../images/common/vicaso_logo_black.png');
    }
  }
}

.header-main-logo{
  a{
    display: inline-block;
    margin-left: 15px;

    img{
      width: 110px;
      height: 33px;
    }
  }
}

.header-user{
  width: 33px;
  height: 33px;
  display: inline-block;
  border-radius: 50%;
  background-color: $green-light;
  margin-right: 10px;
}

.header-main + .header-main-submenu + main, .header-main + main{
  padding-top: 60px;
}

@media(max-width: 575px){
  .header-main, .header-main-submenu{
    text-align: center;

    a{
      font-size: remCalc(18);
      margin: 0 10px;
    }
  }

  .header-main-logo{
    margin: 10px 0 20px;
  }

  .header-right-menu{
    margin-bottom: 10px;
  }
}