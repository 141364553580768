.content-area{
  max-width: 660px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.content-items-area{
  background-color: $grey-bg-4;
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 24px;
  border: 2px solid $grey-bg-4;
  max-width: 580px;

  &.selected{
    border-color: #000;
  }
}

.photos-area{
  min-height: calc(100vh - 117px);
  background-size: cover;
  background-position: center;
}

.map-area{
  min-height: calc(100vh - 117px);
  padding-left: 0 !important;
  padding-right: 0 !important;

  #mapView{
    min-height: 100% !important;
  }
}

.video-area, .threeD-tours{
  padding: 0;

  iframe{
    height: 375px;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media(max-width: 991px){
  .content-area{
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }

  .content-items-area{
    max-width: 100%
  }
}

@media(min-width: 1200px){
  .content-area{
    padding-left: 20px;
    padding-right: 20px;
  }
}

