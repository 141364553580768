[class*="icon"]{
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-eye{
  width: 35px;
  height: 25px;
  background-size: 35px auto;
  background-image: url('../images/icons/eye.png');
  cursor: pointer;
}

.icon-tick{
  background-image: url('../images/icons/tick.png');
  background-size: contain;
  height: 30px;
  width: 33px;
}

.icon-paint{
  background-image: url('../images/icons/paint.png');
  background-size: contain;
  height: 30px;
  width: 33px;
}

.icon-photo{
  background-image: url('../images/icons/photo.png');
  background-size: contain;
  height: 30px;
  width: 33px;
}