$base-font-size: 18;
$base-font-family: 'Inter', sans-serif;

$grey-bg-4:         #F8F8F8;
$grey-bg-3-hover:   #f0f0f0;
$grey-bg-2:         #EFEFEF;
$grey-bg:           #ECECEC;
$grey-bg-5:         #D9D9D9;
$grey-bg-3:         #D2D2D2;

$grey-border:       #CBCBCB;
$grey-border-2:     #CCCCCC;
$grey-border-3:     #BEBEBE;

$grey-3-hover:      #b8b7b7;
$grey-6:            #A4A4A4;
$grey-3:            #949191;
$grey-2:            #969696;
$grey-4-hover:      #8e8d8d;
$grey-4:            #747474;
$grey:              #6B6B6B;
$grey-5:            #515151;

$black-hover:       rgb(56, 56, 56);
$black-2:           #2F2F2F;
$black:             #1D1D1F;

$green-light:       #73D412;
$green:             #82B84D;
$green-2:           #14936D;

$blue:              #356897;
