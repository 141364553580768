.custom-tooltip{
  background-color: black;
  color: white;
  display: inline-block;
  border-radius: 10px;
  padding: 5px 10px 10px;
  font-size: remCalc(12);
  position: relative;
  max-width: 200px;

  &::after{
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: black transparent transparent transparent;
    bottom: -4px;
    left: 50%;
    margin-left: -2px;
  }
}