.btn, a.btn, .header-main a.btn{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: remCalc(25);
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: button;
  padding: 16px 73px 19px;
  border-radius: 37px;
  border: 1px solid;

  &-primary{
    background-color: black;
    border-color: black;
    color: white;

    &:hover, &:active{
      background-color: $black-hover;
      border-color: $black-hover;
    }

    &:disabled{
      opacity: .1;
      cursor: default;

      &:hover, &:active{
        background-color: black;
        border-color: black;
      }
    }
  }

  

  &-secondary{
    background-color: $grey-4;
    border-color: $grey-4;
    color: white;

    &:hover, &:active{
      background-color: $grey-4-hover;
      border-color: $grey-4-hover;
    }
  }

  &-grey{
    color: $black !important;
    background-color: $grey-bg-3;
    border-color: $grey-bg-3;

    &:hover, &:active{
      background-color: $grey-bg-3-hover;
    }
  }

  &-medium{
    font-size: remCalc(18);
    padding: 9px 42px;
  }

  &-medium-2{
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media(min-width: 576px){
  .btn{
    font-size: remCalc(20);
    padding: 13px 73px 14px;

    &-medium{
      font-size: remCalc(15);
    }

    &-medium-2{
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}