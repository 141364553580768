h1, .h1{
  color: black;
  font-weight: 600;
  font-size: remCalc(35);
  line-height: 1.3;
  margin: 0 0 20px;
}

h3.h2{
  color: black;
  font-size: remCalc(30);
  margin: 0 0 12px;
}

$font-sizes: (
 25
);

// $line-height: (
//   18, 28, 32, 50
// );

@each $size in $font-sizes {
  .fs-#{$size} {
    font-size: remCalc($size);
  }
}

// @each $size in $line-height {
//   .lh-#{$size} {
//     line-height: remCalc($size);
//   }
// }

.text-black{
  color: black
}

.text-gallery{
  font-size: remCalc(25);
  color: $grey-bg-3-hover;
  font-weight: 600;
  letter-spacing: -1px; 
}